import React from "react";
import logo from "../assets/logo.png";
import "./Navbar.css";

function Navbar() {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar__left">
        <img src={logo} alt="Logo" className="navbar__logo" />
      </div>
      <div className="navbar__right">
        <button onClick={() => scrollToSection("section-1")} className="navbar__link" style={{ border: "none", background: "none", cursor: "pointer", fontFamily: "inherit" }}>
          Home
        </button>
        <button onClick={() => scrollToSection("section-2")} className="navbar__link" style={{ border: "none", background: "none", cursor: "pointer", fontFamily: "inherit" }}>
          Music
        </button>
        <button onClick={() => scrollToSection("section-3")} className="navbar__link" style={{ border: "none", background: "none", cursor: "pointer", fontFamily: "inherit" }}>
          Contact
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
