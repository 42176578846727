import React from "react";
import "./Socialbar.css";
import { FaInstagram, FaSpotify, FaApple, FaYoutube, FaTiktok, FaSoundcloud, FaFacebook, FaTwitter } from 'react-icons/fa';

const IconSize = 60;
const MarginSize = 0;

function Socialbar() {
  return (
    <div className="social-bar">
      <a className="social-icon" href="https://www.instagram.com/alexskrindo/"><FaInstagram color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
      <a className="social-icon" href="https://open.spotify.com/artist/5LnNAR8Bhv2vJCJzBnCw9P"><FaSpotify color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
      <a className="social-icon" href="https://music.apple.com/us/artist/alex-skrindo/741266166"><FaApple color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
      <a className="social-icon" href="https://www.youtube.com/@AlexSkrindo"><FaYoutube color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
      <a className="social-icon" href="https://www.tiktok.com/@alexskrindomusic"><FaTiktok color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
      <a className="social-icon" href="https://soundcloud.com/alex-skrindo"><FaSoundcloud color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
      <a className="social-icon" href="https://www.facebook.com/AlexanderSkrindo/"><FaFacebook color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
      <a className="social-icon" href="https://twitter.com/AlexSkrindo"><FaTwitter color="white" size={IconSize} style={{ marginRight: MarginSize }} /></a>
    </div>
  );
}

export default Socialbar;
