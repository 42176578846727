import React from "react";

const SpotifyIframe = ({
  link,
  style = {},
  wide = false,
  width = wide ? "100%" : 300,
  height = wide ? "100%" : 380,
  frameBorder = 0,
  allow = "encrypted-media",
  ...props
}) => {
  const url = new URL(link);
  // Normalize the Spotify link
  //   url.pathname = url.pathname.replace(/\/intl-\w+\//, "/");

  return (
    <iframe
      title="Spotify Artist"
      src={url}
      width={width}
      height={height}
      frameBorder={frameBorder}
      allow={allow}
      style={{
        borderRadius: 8,
        ...style
      }}
      {...props}
    />
  );
};

export default SpotifyIframe;
