// import React from 'react';
import {useEffect, useState} from "react";
import './App.css';
import background from './assets/bg.png'
import Socialbar from './components/Socialbar';
import GetInTouch from './components/GetInTouch';
import SpotifyPlay from './components/SpotifyPlay';
import Footer from './components/Footer';
import axios from "axios";
import qs from "qs";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const auth_token = btoa(`${CLIENT_ID}:${CLIENT_SECRET}`, 'utf-8').toString('base64');

function App() {
  const [token, setToken] = useState("")
  
  useEffect(() => {
    const getAuth = async () => {
      try{
        //make post request to SPOTIFY API for access token, sending relavent info
        const token_url = 'https://accounts.spotify.com/api/token';
        const data = qs.stringify({'grant_type':'client_credentials'});
        const response = await axios.post(token_url, data, {
          headers: { 
            'Authorization': `Basic ${auth_token}`,
            'Content-Type': 'application/x-www-form-urlencoded' 
          }
        })
        setToken(response.data.access_token);
      }catch(error){
        console.log(error);
      }
    }

    getAuth();
  }, [])

  return (
    <div className="container">
      <section id="section-1" className="section-1" style={{backgroundImage: `url(${background})`}}>
        <div class="section1__content">
        </div>
        <Socialbar />
      </section>
      <section id="section-2" className="section-2">
        <div className='section2__content'>
          <SpotifyPlay accessToken={token} />
        </div>
      </section>
      <section id="section-3" className="section-3">
        <div className='section3__content'>
          <GetInTouch />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;