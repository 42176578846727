import React from 'react';
import './GetInTouch.css'; // Import the CSS file for Section3

const GetInTouch = () => {
  return (
    <div className="getintouch">
      <h1>Get in touch</h1>
      <div className="contact-info">
        <h3>Promo</h3>
        <div className="contact-item">
          <p>askrindo889@gmail.com</p>
        </div>
        <h3>Management</h3>
        <div className="contact-item">
          <p>evicka.dang@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
