import React, { useState, useEffect, useMemo} from 'react';
import SpotifyWebApi from 'spotify-web-api-js';
import './SpotifyPlay.css';
import SpotifyIframe from './SpotifyIframe';
// import SpotifyPlayer from 'react-spotify-web-playback';

const SpotifyPlay = ({ accessToken }) => { 
    
    const spotifyApi = useMemo(() => {
        const api = new SpotifyWebApi();
        api.setAccessToken(accessToken);
        return api;
    }, [accessToken]);

    const [albums, setAlbums] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    // const [scrollWidth, setScrollWidth] = useState(0);

    useEffect(() => {
        spotifyApi.getArtistAlbums('5LnNAR8Bhv2vJCJzBnCw9P', { limit: 8 })
            .then(data => {
                setAlbums(data.items); // Set albums
                setIsLoaded(true);     // Albums are fully loaded
            })
            .catch(error => console.log(error));
    }, [spotifyApi]);

    // useEffect(() => {
    //     spotifyApi.getArtistAlbums('5LnNAR8Bhv2vJCJzBnCw9P', { limit: 8 })
    //     .then(data => setAlbums(data.items))
    //     .catch(error => console.log(error)); 
    // }, [spotifyApi]);

    // useEffect(() => {
    //     const albumContainer = document.getElementById('albumContainer');
    //     setScrollWidth(albumContainer.scrollWidth);
    //   }, [albums]);
    
    const duplicatedAlbums = [...albums, ...albums]; // Duplicate the array
    
    return (
        <div className='spotifyplay__div'>
            <h2 className="spotify-heading">Latest Music</h2>
            <div className="section2__iframe__div">
                <SpotifyIframe link={"https://open.spotify.com/embed/artist/5LnNAR8Bhv2vJCJzBnCw9P?utm_source=generator"} wide={true}/> 
            </div>
            {/* <div className="section2__iframe__div">
                <iframe className="spotify__iframe" src="https://open.spotify.com/embed/artist/5LnNAR8Bhv2vJCJzBnCw9P?utm_source=generator" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title="Spotify Artist"></iframe>
            </div> */}
            <h2 className="spotify-heading">Latest Albums</h2>
            {isLoaded && ( // Only render when albums are fully loaded
            <div className="spotify-bar" id="albumContainer">
                {duplicatedAlbums.map(album => (
                <div key={album.id}>
                    <img src={album.images[0].url} alt={album.name} />
                    <p>{album.name}</p>
                </div>
                ))}
                {/* {scrollWidth > 0 && albums.map(album => (
                <div key={album.id}>
                    <img src={album.images[0].url} alt={album.name} />
                    <p>{album.name}</p>
                </div>
                ))} */}
            </div>
            )}
        </div>
    );
}

export default SpotifyPlay;
