import React from 'react';
import './Footer.css'; 
import SocialbarMini from './SocialbarMini';

const Footer = () => {
  return (
    <footer className="footer">
        <SocialbarMini />
        <br />
      <div className="footer-content">
        <p className="copyright">
          &copy; {new Date().getFullYear()} AlexSkrindo. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
